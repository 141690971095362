<script>
import {getSsoAuthUrl, getUserInfo, ticketByToken} from "@/api/login";

export default {
  name: "index",
  created() {
    this.$store.dispatch('setUser', {})
    sessionStorage.clear()
    localStorage.clear()

    const { ticket, back } = this.$route.query
    console.log(ticket, back)
    if (ticket) {
      this.login(ticket)
    } else {
      this.goSsoAuthUrl(back || window.location.origin)
    }
  },
  methods: {
    login(ticket) {
      ticketByToken({ ticket }).then(res => {
        if (res.data.code === 200) {
          localStorage.setItem('accessToken', res.data.data)
          getUserInfo().then(res => {
            if (res.data.code === 200) {
              this.$store.dispatch('setUser', { username: res.data.data.name, ...res.data.data })
              this.$router.push({ path: '/home', replace: true})
            }
          })
        }
      })
    },
    goSsoAuthUrl(backUrl) {
      getSsoAuthUrl({ clientLoginUrl: window.location.href, backUrl }).then(response => {
        window.location.href = response.data.data
      })
    }
  }
}
</script>

<template>
  <div class="login loading">
    <div class="loading-box">
      <div class="loading-wrap">
        <span class="dot dot-spin">
          <i />
          <i />
          <i />
          <i />
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@keyframes ant-rotate {
  to {
    transform: rotate(405deg);
  }
}

@keyframes ant-spin-move {
  to {
    opacity: 1;
  }
}
.login {
  width: 100vw;
  height: 100vh;
  .loading {
    &-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    &-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 98px;

      .dot {
        position: relative;
        box-sizing: border-box;
        display: inline-block;
        width: 32px;
        height: 32px;
        font-size: 32px;
        transform: rotate(45deg);
        animation: ant-rotate 1.2s infinite linear;
      }

      .dot i {
        position: absolute;
        display: block;
        width: 14px;
        height: 14px;
        background-color: #1890ff;
        border-radius: 100%;
        opacity: 0.3;
        transform: scale(0.75);
        transform-origin: 50% 50%;
        animation: ant-spin-move 1s infinite linear alternate;
      }
      .dot i:nth-child(1) {
        top: 0;
        left: 0;
      }
      .dot i:nth-child(2) {
        top: 0;
        right: 0;
        animation-delay: 0.4s;
      }
      .dot i:nth-child(3) {
        right: 0;
        bottom: 0;
        animation-delay: 0.8s;
      }
      .dot i:nth-child(4) {
        bottom: 0;
        left: 0;
        animation-delay: 1.2s;
      }
    }
  }
}
</style>
